import { createSlice } from '@reduxjs/toolkit';
import moment from "moment";

export const HOME_PAGE = 'home';
export const LOGIN_PAGE = 'login';

const defaultWeekStr = localStorage.getItem('dam_admin_week') || `${moment().year()}-W${String(moment().isoWeek()).padStart(2, '0')}`;

const navSlice = createSlice({
    name: 'nav',
    initialState: {
        page: LOGIN_PAGE,
        extra: null,
        loading: 0,
        hideSpinner: false,
        showHeader: true,
        selectedWeek: defaultWeekStr,
        selectedBranch: '',
        startDate: moment().startOf('month').format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
    },
    reducers: {
        setExtra( state, action) {
            state.extra = action.payload
        },
        setPage(state, action) {
            //console.log("Setting page to: " + action.payload)
            state.page = action.payload
        },
        setPageAndExtra(state, action) {
            state.page = action.payload.page
            state.extra = action.payload.extra
        },
        setLoading(state, action) {
            state.loading = action.payload === true ? state.loading + 1 : state.loading - 1
        },
        setSpinnerVisibility(state, action) {
            state.hideSpinner = action.payload
        },
        setShowHeader(state, action) {
            state.showHeader = action.payload
        },
        setSelectedWeek(state, action) {
            state.selectedWeek = action.payload
            localStorage.setItem('dam_admin_week', action.payload);
        },
        setSelectedBranch(state, action) {
            state.selectedBranch = action.payload
            localStorage.setItem('dam_admin_branch', action.payload);
        },
        setStartDate(state, action) {
            state.startDate = moment(action.payload).format("YYYY-MM-DD");
        },
        setEndDate(state, action) {
            state.endDate = moment(action.payload).format("YYYY-MM-DD");
        },
    },
});

export const {
    setSelectedBranch,
    setSelectedWeek,
    setPage,
    setPageAndExtra,
    setLoading,
    setExtra,
    setSpinnerVisibility,
    setShowHeader,
    setStartDate,
    setEndDate,
} = navSlice.actions
export default navSlice.reducer;
