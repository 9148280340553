import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {createTheme} from '@mui/material/styles'
import {Alert, Backdrop, ThemeProvider} from "@mui/material";
import {Provider, useDispatch, useSelector} from "react-redux";
import store from './store/store';
import {clearError} from "./store/slices/errorSlice";
import "@fontsource/roboto";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import VersionChecker from "./containers/VersionChecker";
import {Slide, ToastContainer} from "react-toastify";
import reportWebVitals from "./reportWebVitals";

const theme = createTheme({
    palette: {
        type: 'dark',
    },
    typography: {
        fontFamily: 'Roboto'
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    overrides: {
        MuiContainer: {
            root: {
                '@media (min-width: 1280px)': {
                    margin: "0px"
                },
            },
        },
    },
    components: {
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    padding: '8px',
                    fontSize: '0.5rem',
                    lineHeight: '1.2',
                },
            }
        },
        MuiContainer: {
            defaultProps: {
                maxWidth: false, // Disable max-width globally
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    disableGutters: true,
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                size: 'small',
            },
        },
    },
});


const root = ReactDOM.createRoot(document.getElementById('root'));

const MyErrorBackdrop = () => {
    const error = useSelector(state => state.error?.error);
    const severity = useSelector(state => state.error?.severity);
    const dispatch = useDispatch();

    return (
        error !== null ?
            <Backdrop onClick={() => dispatch(clearError())}
                      sx={{color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1}}
                      open={true}
            >
                <Alert severity={severity}>{error}</Alert>
            </Backdrop>
            : null
    )
}

console.log('render root');

root.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <MyErrorBackdrop/>
            <VersionChecker/>
            <ToastContainer
                limit={10}
                position="top-right"
                autoClose={10000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={true}
                theme="light"
                toastStyle={{fontSize: '1 rem', fontFamily: 'Roboto'}}
                transition={Slide}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <StrictMode>
                    <App/>
                </StrictMode>
            </LocalizationProvider>
        </Provider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log)
