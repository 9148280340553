import React, {useEffect, useState} from 'react';
import {ERROR, setError} from "../store/slices/errorSlice";
import {useDispatch, useSelector} from "react-redux";
import {Backdrop, Modal, Paper, Typography} from "@mui/material";
import {logoutUser} from "../store/slices/authThunks";
import axios from "../store/tokenAxios/tokenAxios";

const IdleTimer = () => {
    const idleTimeout = process.env.REACT_APP_API_INACTIVITY_TIMEOUT;
    const [showWarning, setShowWarning] = useState(false);
    const [remaining, setRemaining] = useState(0);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        const events = ['mousemove', 'keydown', 'scroll'];
        // Check for Web Worker support
        if (window.Worker) {
            const timerWorker = new Worker('workers/timerWorker.js');
            const resetIdleTimer = () => {
                timerWorker.postMessage({type: 'reset', duration: idleTimeout});
            }

            events.forEach((event) => {
                window.addEventListener(event, resetIdleTimer);
            });

            if (auth && auth.token) {
                timerWorker.onmessage = (e) => {
                    if (e.data.type === 'expired') {
                        dispatch(logoutUser("Inactivity timeout", axios));
                        setShowWarning(false);
                    }
                    if (e.data.type === 'tick') {
                        const hasExpired = e.data.remaining < 30;

                        setRemaining(e.data.remaining);

                        setShowWarning(prevState => {
                            if (prevState !== hasExpired) {
                                return hasExpired;
                            } else {
                                return prevState;
                            }
                        });
                    }
                };

                resetIdleTimer();
            }

            return () => {
                events.forEach((event) => {
                    window.removeEventListener(event, resetIdleTimer);
                });

                timerWorker.terminate();
            }
        } else {
            dispatch(setError({
                error: "Unsupported browser",
                severity: ERROR
            }));
        }
    }, [auth, dispatch, idleTimeout]);

    return (
        <>
            {showWarning &&
                <Modal open>
                    <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.modal + 2}} open={true}>
                        <Paper elevation={3} style={{
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '20px',
                            maxWidth: '400px',
                            textAlign: 'center'

                        }}>
                            <Typography variant="h5" color="inherit">
                                Session will expire soon!
                            </Typography>
                            <Typography variant="h6" color="inherit">
                                You will be automatically logged out for security reasons
                                due to inactivity in:
                            </Typography>
                            <Typography variant="h4" color="inherit">
                                {remaining} seconds
                            </Typography>
                            <Typography variant="h6" color="inherit">
                                Type or move your mouse to cancel
                            </Typography>
                        </Paper>
                    </Backdrop>
                </Modal>
            }
        </>
    )
}

export default IdleTimer;

