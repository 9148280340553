import {createSlice} from "@reduxjs/toolkit";

const signalsSlice = createSlice({
    name: 'signals',
    initialState: {
        accessControlEventSignal: Date.now()
    },
    reducers: {
        setAccessControlEventSignal(state, action) {
            state.accessControlEventSignal = action.payload;
        }
    }
})

export const {
    setAccessControlEventSignal
} = signalsSlice.actions

export default signalsSlice.reducer;