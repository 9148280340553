import React from 'react';
import { useFormikContext } from 'formik';
import { FormControl, FormLabel, Input } from '@mui/material';

const FileUploadField = ({ name, label, readonly, accept = "image/*" }) => {
    const { setFieldValue } = useFormikContext();

    const handleFileChange = (event) => {
        const file = event.currentTarget.files[0];
        setFieldValue(name, file);
    };

    return (
        <FormControl fullWidth variant="outlined" disabled={readonly || false}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Input
                id={name}
                name={name}
                type="file"
                onChange={handleFileChange}
                disabled={readonly}
                inputProps={{
                    accept: accept,
                }}
            />
        </FormControl>
    );
};

export default FileUploadField;
