import React from 'react';
import { useFormikContext } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment'; // Ensure moment is imported

const DatePickerField = ({ label, name, readonly }) => {
    const { values, setFieldValue } = useFormikContext();
    let selectedDate = values[name];

    // Convert selectedDate to a Moment object if it's not null or already a Moment instance
    if (selectedDate && !moment.isMoment(selectedDate)) {
        selectedDate = moment(selectedDate);
    }

    const handleDateChange = (date) => {
        // Format the date as needed or use it directly if your backend accepts Date objects
        setFieldValue(name, date ? date.toISOString() : null);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                label={label}
                value={selectedDate || null} // Ensure the value is null if not set
                onChange={handleDateChange}
                readOnly={readonly}
                slotProps={{ textField: { variant: 'outlined' } }}
                disabled={readonly || false}
            />
        </LocalizationProvider>
    );
};

export default DatePickerField;
