import axios from "../../store/tokenAxios/tokenAxios";
import {enqueueSnackbar} from "./SnackbarHelper";

class Helpers {
    static parseError(data) {
        if (data?.response?.status === 401) {
            return null;
        }
        const errorMessages = data?.response?.data?.errors;
        if (errorMessages) {
            const errorList = Object.values(errorMessages).flat();
            const errorMessage = errorList.join(', ');  // Combine the error messages into a single string
            return errorMessage;  // Display the error message in the component
        } else {
            // Check for message at data.response.data.message if data.message is undefined
            return data?.response?.data?.message || data?.response?.data?.error || data.message || 'Unknown error';
        }
    }

    static isEmpty(obj) {
        // If the object is null or undefined, it is considered empty
        if (!obj) {
            return true;
        }

        // If the object has any own properties, it is not considered empty
        if (Object.getOwnPropertyNames(obj).length > 0) {
            return false;
        }

        // Recursively check nested objects
        for (let key in obj) {
            if (!Helpers.isEmpty(obj[key])) {
                return false;
            }
        }

        // If all nested objects are empty, the object is considered empty
        return true;
    }

    static objectToCsv(data) {
        const csvRows = [];
        let headers;

        // If it's an array of objects
        if (Array.isArray(data) && typeof data[0] === "object") {
            headers = Object.keys(data[0]);
            csvRows.push(headers.join(','));

            for (const row of data) {
                const values = headers.map(header => {
                    const escaped = ('' + row[header]).replace(/"/g, '\\"');
                    return `"${escaped}"`;
                });
                csvRows.push(values.join(','));
            }
        }

        // If it's an array of simple data types (numbers, strings)
        else if (Array.isArray(data)) {
            for (const row of data) {
                const escaped = ('' + row).replace(/"/g, '\\"');
                csvRows.push(`"${escaped}"`);
            }
        }

        // If it's a simple object
        else if (typeof data === "object") {
            headers = Object.keys(data);
            csvRows.push(headers.join(','));

            const values = headers.map(header => {
                const escaped = ('' + data[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }

        return csvRows.join('\n');
    };

    static sendTelegramLink( driverId) {
        axios.get(`/driver-api/telegram/send-deeplink/${driverId}`
        ).then((response) => {
            enqueueSnackbar(response.data, {variant: 'success'});
        }).catch((error) => {
            const show = Helpers.parseError(error);
            enqueueSnackbar(show, {variant: 'error'});
        });
    }

    static sendAndroidLink( userId) {
        axios.get(`/audit-api/assets/send-deeplink/${userId}`
        ).then((response) => {
            enqueueSnackbar(response.data, {variant: 'success'});
        }).catch((error) => {
            const show = Helpers.parseError(error);
            enqueueSnackbar(show, {variant: 'error'});
        });
    }

    static sendAndroidDriverLink( driverId) {
        axios.get(`/driver-api/android/send-wingman-deeplink/${driverId}`
        ).then((response) => {
            enqueueSnackbar(response.data, {variant: 'success'});
        }).catch((error) => {
            const show = Helpers.parseError(error);
            enqueueSnackbar(show, {variant: 'error'});
        });
    }
}

export default Helpers;

