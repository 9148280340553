import React from 'react';
import css from './Base.module.css';
import PropTypes from "prop-types";
function Base(props) {
    return (
        <div className={css.main}>
            {props.children}
        </div>
    );
}

Base.propTypes = {
    children: PropTypes.object.isRequired
}
export default Base;
