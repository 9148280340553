import React from 'react';
import { Divider, Box, Typography } from '@mui/material';

const LabelDivider = ({ label, ...props }) => {
    return (
        <div>
            <Typography variant="body1" mr={2}>{label}</Typography>
            <Divider {...props}/>
        </div>
    );
};

export default LabelDivider;
