import React, {useState} from 'react';
import {Paper, Typography, Box, Button, Divider} from "@mui/material";
import {setAuth} from "../../store/slices/authSlice";
import css from "./Login.module.css";
import * as Yup from "yup";
import FormComponent, {FieldTypes} from "../../components/formik/FormComponent";
import {useDispatch} from "react-redux";
import axios from "../../store/tokenAxios/tokenAxios";
import Helpers from "../../components/helpers/Helpers";
import {INFO, setError} from "../../store/slices/errorSlice";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Otp = ({email, password, userInfo, onCancel}) => {
    const dispatch = useDispatch();
    const [emailSent, setEmailSent] = useState(null);
    const [whatsappSent, setWhatsappSent] = useState(null);

    const requestEmail = () => {
        setEmailSent(false);
        setWhatsappSent(false);
        axios.post(`/api/auth/email-otp`, {
            user_id_hash: userInfo.userIdHash,
        }).then((response) => {

        }).catch((error) => {
            const show = Helpers.parseError(error);
            dispatch(setError({
                error: show,
                severity: INFO
            }));
        }).finally(() => {
            setEmailSent(true);
        });
    }

    const requestWhatsapp = () => {
        setWhatsappSent(false);
        setEmailSent(false);
        axios.post(`/api/auth/whatsapp-otp`, {
            user_id_hash: userInfo.userIdHash,
        }).then((response) => {

        }).catch((error) => {
            const show = Helpers.parseError(error);
            dispatch(setError({
                error: show,
                severity: INFO
            }));
        }).finally(() => {
            setWhatsappSent(true);
        });
    }


    const onLoggedIn = (response, values) => {
        dispatch(setAuth(response.data));
        localStorage.setItem('dam_admin_email', values.email);
    }

    const formFields = [
        {
            label: 'OTP',
            name: 'otp',
            variant: 'outlined',
            fullWidth: true,
            gridFullWidth: true,
            inputMask: /[0-9A-Z]/,
            uppercase: true,
            length: 6,
            type: FieldTypes.TEXT,
        },
    ];

    const validationSchema =
        Yup.object().shape({
            otp: Yup.string().required('Please use your OTP to login').length(6).uppercase()
        });

    const initialValues = {
        otp: '',
    };

    //console.log(initialValues, validationSchema, formFields)

    const devicePretty = userInfo.transport === 'audit' ? 'IC Audit App' : userInfo.transport;

    return (
        <Paper component={Box} p={4} textAlign="center" className={css.paper}>
            <Typography variant="h6" gutterBottom>
                Vaal Triangle Couriers Admin
            </Typography>
            <Typography variant="body" gutterBottom>
                Enter the OTP that was sent to your {devicePretty}
            </Typography>
            <FormComponent
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onLoggedIn}
                formFields={formFields}
                submitLabel="Sign In"
                url={`/api/auth/login`}
                verb="post"
                additionalData={{
                    country: "27",
                    email: email,
                    password: password
                }}
            />

            <Box mt={3}>
                { userInfo.transport !== 'email' ?
                emailSent ?
                    <Box display="flex" alignItems="center" gap={1}>
                        <CheckCircleIcon style={{color: 'green'}}/>
                        <Typography variant="h6" gutterBottom>
                            Check your email for the OTP that was sent to you
                        </Typography>
                    </Box>
                    :
                    <Button
                        onClick={requestEmail}
                        variant="outlined"
                        color="secondary"
                        type="button"
                        disabled={emailSent === false}
                        fullWidth
                    >
                        Email your OTP
                    </Button>
                : <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="body2" gutterBottom>
                            There was a problem sending your OTP via {devicePretty}, please check your email
                        </Typography>
                    </Box>
                }
                <Divider>or</Divider>
                { userInfo.transport !== 'sms' ?
                    whatsappSent ?
                        <Box display="flex" alignItems="center" gap={1}>
                            <CheckCircleIcon style={{color: 'green'}}/>
                            <Typography variant="h6" gutterBottom>
                                Check your SMS for the OTP that was sent to you
                            </Typography>
                        </Box>
                        :
                        <Button
                            onClick={requestWhatsapp}
                            variant="outlined"
                            color="secondary"
                            type="button"
                            disabled={emailSent === false}
                            fullWidth
                        >
                            SMS your OTP
                        </Button>
                    : <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="body2" gutterBottom>
                            There was a problem sending your OTP via {devicePretty}, please check your email
                        </Typography>
                    </Box>
                }
                <Button
                    sx={{marginTop: '20px'}}
                    onClick={onCancel}
                    variant="contained"
                    color="secondary"
                    type="button"
                >
                    Cancel
                </Button>
            </Box>
        </Paper>
    );
};

export default Otp;
