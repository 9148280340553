import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import Terms from "../../containers/terms/Terms";

export const TermsDialog = ({children, ...props}) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <span onClick={handleClickOpen} style={{ cursor: 'pointer' }}>{children}</span>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} {...props}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Terms and Conditions
                </DialogTitle>
                <DialogContent dividers>
                    <Terms/>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

