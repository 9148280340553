import React, {useState, useEffect} from 'react';
import axios from "../store/tokenAxios/tokenAxios";
import {version} from "../config/version";
import {Backdrop, Button, Paper, Typography} from "@mui/material";

const VersionChecker = ({children}) => {
    const [newVersionAvailable, setNewVersionAvailable] = useState(false);

    useEffect(() => {
        const checkVersion = () => {
            const thisVersion = version;
            axios.get('/api/app/get-client-version', {
                nospinner: true,
            }).then((response) => {
                if (compareVersions(response.data.version, thisVersion) > 0) {
                    setNewVersionAvailable(response.data.version);
                }
            }).catch((error) => {
                console.log(error);
            });
        }

        checkVersion();

        // Set up interval to check version every 5 minutes
        const intervalId = setInterval(checkVersion, 300000); // 300000ms = 5 minutes

        // Clear interval on unmount
        return () => clearInterval(intervalId);
    }, []);

    function compareVersions(version1, version2) {
        const v1 = version1.split('.').map(Number);
        const v2 = version2.split('.').map(Number);

        for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
            const num1 = v1[i] || 0;
            const num2 = v2[i] || 0;

            if (num1 > num2) {
                return 1;
            }
            if (num2 > num1) {
                return -1;
            }
        }
        return 0;
    }
    const handleRefresh = () => {
        window.location.reload();
    };

    return newVersionAvailable !== false && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 2 }} open={true}>
            <Paper elevation={3} style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                <Typography variant="h6" color="inherit">
                    A new version {newVersionAvailable} is available.
                </Typography>
                <Typography variant="subtitle1">
                    Please refresh your browser.
                </Typography>
                <Button variant="contained" color="primary" onClick={handleRefresh}>
                    Refresh
                </Button>
            </Paper>
        </Backdrop>
    );
}
export default VersionChecker;
