import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';

const ConfirmAction = ({disabled = false, onOk, icon, enabledColor = "primary", toolTip = ""}) => {
    const [confirmDelete, setConfirmDelete] = useState(false);

    return (
        <Tooltip title={toolTip}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0}}>
                <Fade in={!confirmDelete}>
                    <IconButton
                        color={enabledColor}
                        disabled={disabled}
                        onClick={(event) => {
                            event.stopPropagation();
                            setConfirmDelete(true);
                        }}
                        style={{display: confirmDelete ? 'none' : 'block'}}
                    >
                        {icon}
                    </IconButton>
                </Fade>

                <Fade in={confirmDelete}>
                    <div style={{display: confirmDelete ? 'flex' : 'none', alignItems: 'center'}}>
                        <IconButton onClick={(event) => {
                            event.stopPropagation();
                            onOk();
                            setConfirmDelete(false);
                        }}>
                            <CheckIcon fontSize="small" color="success"/>
                        </IconButton>
                        <IconButton onClick={(event) => {
                            event.stopPropagation();
                            setConfirmDelete(false);
                        }}>
                            <CloseIcon fontSize="small" color="error"/>
                        </IconButton>
                    </div>
                </Fade>
            </div>
        </Tooltip>
    );
};

export default ConfirmAction;
