import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import {Button, FormControl, FormLabel, TextField, IconButton, InputLabel, Box} from '@mui/material';

import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

const StringListField = ({ name, label, aliasType, readonly }) => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <FormControl fullWidth component="fieldset" disabled={readonly || false}>
            <InputLabel shrink>{label}</InputLabel>
            <FieldArray
                name={name}
                render={arrayHelpers => (
                    <Box display="flex" alignItems="center" gap={2} flexWrap={"wrap"}>
                        {values[name] && values[name].length > 0 ? (
                            values[name].map((string, index) => (
                                <div key={`div${index}`}>
                                    <TextField
                                        InputProps={{
                                            size: 'small'
                                        }}
                                        value={string}
                                        onChange={e => {
                                            const updatedStrings = [...values[name]];
                                            updatedStrings[index] = e.target.value;
                                            setFieldValue(name, updatedStrings);
                                        }}
                                    />
                                    <IconButton
                                        onClick={() => arrayHelpers.remove(index)}
                                        disabled={readonly}
                                    >
                                        <DeleteForeverRoundedIcon />
                                    </IconButton>
                                </div>
                            ))
                        ) : null}
                        <Button
                            onClick={() => arrayHelpers.push('')}
                            disabled={readonly}
                        >
                            Add {aliasType}
                        </Button>
                    </Box>
                )}
            />
        </FormControl>
    );
};

export default StringListField;
