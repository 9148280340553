import React, {useEffect, useState} from 'react';
import {Paper, Typography, Box, Button, Backdrop} from '@mui/material';
import Grid from '@mui/material/Grid';
import Base from '../base/Base';
import css from './Login.module.css';
import * as Yup from 'yup';
import FormComponent from "../../components/formik/FormComponent";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";


const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
        .min(6, 'Password must be at least 6 characters'),
    password_confirmation: Yup.string()
        .required('Password confirm is required')
        .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
});

const formFields = [
    {
        label: 'New Password',
        name: 'password',
        variant: 'outlined',
        fullWidth: true,
        type: 'password',
    },
    {
        label: 'Confirm New Password',
        name: 'password_confirmation',
        variant: 'outlined',
        fullWidth: true,
        type: 'password',
    },
];

const initialValues = {}

const ConfirmReset = ({mode='reset'}) => {
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const [done, setDone] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const extra = location.state?.extra
    const [showError, setShowError] = useState(null);
    const [resetParams, setResetParams] = useState({
        token: null,
        email: null,
    });

    const onReset = (response, values) => {
        setDone(true);
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        const email = queryParams.get('email');
        if (token && email) {
            setResetParams({
                token,
                email,
            })
        } else {
            setShowError("Invalid reset link");
        }
    }, [location]);

    return (
        <Base>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Paper component={Box} p={4} textAlign="center" className={css.paper}>
                        <Typography variant="h6" gutterBottom>
                            {mode === 'reset' ? 'Reset Password' : 'Set Password'}
                        </Typography>
                        {showError !== null ?
                            <Backdrop
                                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1}}
                                open={true}
                            >
                                <Paper component={Box} p={4}>
                                    <Typography variant="h6" gutterBottom>
                                        {showError}
                                    </Typography>
                                    <Button variant={"contained"} onClick={() => navigate('/login')}>Close</Button>
                                </Paper>
                            </Backdrop>
                            :
                            <>
                                {!done &&
                                    <>
                                        <FormComponent
                                            initialValues={initialValues}
                                            validationSchema={validationSchema}
                                            onSubmit={onReset}
                                            formFields={formFields}
                                            submitLabel={mode === 'reset' ? 'Reset Password' : 'Set Password'}
                                            url={`/api/auth/password/reset`}
                                            verb="post"
                                            additionalData={{
                                                token: resetParams.token,
                                                email: resetParams.email
                                            }}
                                        />
                                        <Box mt={3}>
                                            <Button
                                                onClick={() => navigate('/login')}
                                                variant="contained"
                                                color="secondary"
                                                type="button"
                                                fullWidth
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </>
                                }
                                {done &&
                                    <>
                                        <Typography variant="body2" color="secondary" gutterBottom>
                                            { mode === 'reset' ? 'Password reset successfully' : 'Password set successfully' }
                                        </Typography>
                                        <Box mt={3}>
                                            <Button
                                                onClick={() => navigate('/login')}
                                                variant="contained"
                                                color="secondary"
                                                type="button"
                                                fullWidth
                                            >
                                                LOGIN
                                            </Button>
                                        </Box>
                                    </>
                                }
                            </>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Base>
    )
}

export default ConfirmReset;
