import { createSlice } from '@reduxjs/toolkit';

export const ERROR = 'error';
export const WARNING = 'warning';
export const INFO = 'info';
export const SUCCESS = 'success';


const errorSlice = createSlice({
    name: 'error',
    initialState: {
        severity: ERROR,
        error: null,
    },
    reducers: {
        setError(state, action) {
            if( action.payload.error !== "Unauthenticated." ) {
                state.error = action.payload.error;
                state.severity = action.payload.severity;
            }
        },
        clearError(state) {
            state.error = null;
        }
    },
});

export const { setError, clearError } = errorSlice.actions
export default errorSlice.reducer;
