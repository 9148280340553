import React from 'react';
import { FormControl, InputLabel, FormControlLabel, Checkbox, Box } from '@mui/material';
import HintField from "./HintField";

const CheckboxGroup = ({ name, label, options, horizontal = false }) => {
    return (
        <FormControl>
            <InputLabel shrink>{label}</InputLabel>
            <Box display="flex" flexDirection={horizontal ? "row" : "column"} flexWrap="wrap">
                {options.map((option) => (
                    <HintField key={`cbg${option.value}`} name={name}>
                        {({ form }) => {
                            const fieldProps = form.getFieldProps(name);
                            const isChecked = form.values[name].includes(option.value);

                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...fieldProps}
                                            key={`cbg_${option.value}`}
                                            checked={isChecked}
                                            value={option.value}
                                            color="primary"
                                            onChange={() => {
                                                if (isChecked) {
                                                    const newValues = form.values[name].filter(
                                                        (value) => value !== option.value
                                                    );
                                                    form.setFieldValue(name, newValues);
                                                } else {
                                                    const newValues = [
                                                        ...form.values[name],
                                                        option.value,
                                                    ];
                                                    form.setFieldValue(name, newValues);
                                                }
                                            }}
                                        />
                                    }
                                    label={option.label}
                                />
                            );
                        }}
                    </HintField>
                ))}
            </Box>
        </FormControl>
    );
};

export default CheckboxGroup;
