import { configureStore } from '@reduxjs/toolkit';
import authSlice from "./slices/authSlice";
import navSlice from "./slices/navSlice";
import errorSlice from "./slices/errorSlice";
import dataSlice from "./slices/dataSlice";
import signalsSlice from "./slices/signalsSlice";

const rootReducer = {
    error: errorSlice,
    auth: authSlice,
    nav: navSlice,
    data: dataSlice,
    signals: signalsSlice
};

const store = configureStore({
    reducer: rootReducer,
});

export default store;
