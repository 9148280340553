import React from 'react';
import { Field, useFormikContext } from 'formik';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import HintField from "./HintField";

const RadioGroupField = ({ name, label, options, readonly }) => {
    const { setFieldValue } = useFormikContext();

    return (
        <FormControl fullWidth component="fieldset" disabled={readonly || false}>
            <FormLabel component="legend">{label}</FormLabel>
            <HintField
                as={RadioGroup}
                aria-label={label}
                name={name}
                onChange={(e) => setFieldValue(name, e.target.value)}
            >
                {options.map((option) => (
                    <FormControlLabel
                        key={`rgf_${option.value}`}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                    />
                ))}
            </HintField>
        </FormControl>
    );
};

export default RadioGroupField;
