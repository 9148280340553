import React from 'react';
import {green, orange, red} from "@mui/material/colors";
import {FiberManualRecord} from "@mui/icons-material";


const ConnectionIndicator = ({isConnected}) => {

    const color = isConnected === 'connected' ? green[500] : (isConnected === 'connecting' ? orange[500] : red[500]);

    console.log('State changed ConnectionIndicator render', isConnected);

    return (
        <FiberManualRecord style={{ color: color }} />
    );
};

export default ConnectionIndicator;
