import React from 'react';
import { TextField } from '@mui/material';
import HintField from "./HintField";

const TextFieldField = ({ form, errors, touched, uppercase=false, inputMask = /./, maskType = 'text', ...field }) => {

    const handleChange = (e) => {
        if( field.readonly ) return;

        const newValue = uppercase?e.target.value.toUpperCase():e.target.value;
        // Filter out any characters that don't match the inputMask
        const filteredValue = Array.from(newValue)
            .filter(char => inputMask.test(char))
            .join('');

        // Set the filtered value using Formik's setFieldValue method
        form.setFieldValue(field.name, filteredValue);
    };

    return (
        <HintField
            as={TextField}
            {...field}
            type={maskType === 'password' ? 'password' : 'text'}
            error={errors[field.name] && touched[field.name]}
            helperText={errors[field.name] && touched[field.name] && errors[field.name]}
            InputLabelProps={{ shrink: true }}
            autoComplete="off"
            InputProps={{
                readonly: field.readonly || false,
                size: 'small'
            }}
            onChange={handleChange}
        />
    );
};

export default TextFieldField;
