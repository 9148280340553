import Echo from 'laravel-echo';
import { useState, useEffect } from 'react';
window.Pusher = require('pusher-js');

if (undefined === window.echoInstance)
    window.echoInstance = null;

export function authHeader() {
    const token = localStorage.getItem('dam_admin_token');
    if (token) {
        return `Bearer ${JSON.parse(token).token}`;
    }
    return null;
}

/*
var pusher = new Pusher("app_key", {
  userAuthentication: {
    endpoint: "/pusher/user-auth",
    transport: "ajax",
    params: {},
    headers: {},
    customHandler: null,
  },
});
 */

const createEchoInstance = () => {
    //const urlObject = new URL(process.env.REACT_APP_API_BASE_URL);
    //const baseUrl = `${urlObject.hostname}`;

    if (!window.echoInstance) {
        const options = {
            authEndpoint: process.env.REACT_APP_API_BASE_URL + '/api/auth/authenticate',
            broadcaster: 'pusher',
            key: process.env.REACT_APP_API_MIX_PUSHER_APP_KEY,
            wsHost: process.env.REACT_APP_API_PUSHER_URL, //baseUrl,
            wsPort: parseInt( process.env.REACT_APP_API_PUSHER_PORT),
            wssPort: parseInt( process.env.REACT_APP_API_PUSHER_PORT_SSL),
            encrypted: true,
            disableStats: true, // this disables stats for stats.pusher.com, we dont use this
            forceTLS: process.env.REACT_APP_API_PUSHER_FORCE_TLS==='true',
            cluster: "bg1",
            enabledTransports: ['ws', 'wss'],
            auth: {
                headers: {
                    Authorization: authHeader(),
                }
            }
        };

        //console.log('echo options', process.env.REACT_APP_API_PUSHER_FORCE_TLS, options);

        try {
            window.echoInstance = new Echo(options);
        } catch( error) {
            console.error('echo error', error);
        }
    }
    return window.echoInstance;
};

/*export const getEchoInstance = (onConnectedCallback=null, setPusherConnected=null) => {
    return createEchoInstance(onConnectedCallback, setPusherConnected);
};*/

export function useEchoInstance(onConnectedCallback = null) {
    const [connectionState, setConnectionState] = useState("disconnected");
    const [echoInstance, setEchoInstance] = useState(null);

    useEffect(() => {
        const instance = createEchoInstance();
        setEchoInstance(instance);

        if( instance && instance.connector && instance.connector.pusher && instance.connector.pusher.connection) {
            // Define an event handler that updates local state and calls the callback
            const handler = states => {
                console.log('State changed from', states.previous, 'to', states.current);
                setConnectionState(states.current);
                if (onConnectedCallback) onConnectedCallback(states.current);
            };

            // Bind the event every time the hook mounts
            instance.connector.pusher.connection.bind('state_change', handler);

            // Clean up the binding on unmount
            return () => {
                instance.connector.pusher.connection.unbind('state_change', handler);
            };
        }
    }, [onConnectedCallback]);

    console.log('useEchoInstance', echoInstance, connectionState);

    return { echoInstance, connectionState };
}