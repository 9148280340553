const entrySound = require('../assets/sound/doorbell-5.mp3');
const errorSound = require('../assets/sound/error.mp3');
const messageSound = require('../assets/sound/message.mp3');
const popSound = require('../assets/sound/happy-pop-3.mp3');
const newMessageSound = require('../assets/sound/new-message.mp3');
const littleBellSound = require('../assets/sound/little-bell.mp3');
const beep = require('../assets/sound/scanner-beep-sound.mp3');
const scannerSound = require('../assets/sound/store-scanner-beep-90395.mp3');
const failSound = require('../assets/sound/fail.mp3');
const dingDingSound = require('../assets/sound/din-ding-89718.mp3');


const useSoundPlayer = () => {
    const playSound = (soundFile) => {
        const newAudio = new Audio(soundFile);
        newAudio.play().catch(error => console.error('Error playing sound:', error));
    };

    const playMessageSound = () => playSound(messageSound);
    const playErrorSound = () => playSound(errorSound);
    const playEntrySound = () => playSound(entrySound);
    const playPopSound = () => playSound(popSound);
    const playNewMessageSound = () => playSound(newMessageSound);
    const playLittleBellSound = () => playSound(littleBellSound);
    const playBeepSound = () => playSound(beep);
    const playScannerSound = () => playSound(scannerSound);
    const playFailSound = () => playSound(failSound);
    const playDingDingSound = () => playSound(dingDingSound);

    return {
        playMessageSound,
        playErrorSound,
        playEntrySound,
        playPopSound,
        playNewMessageSound,
        playLittleBellSound,
        playBeepSound,
        playScannerSound,
        playFailSound,
        playDingDingSound,
    };
};

export default useSoundPlayer;
