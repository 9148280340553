import React from 'react';
import { Typography, Container } from '@mui/material';

function TermsAndConditions() {
    return (
        <Container maxWidth="md">
            <Typography variant="body1" paragraph>
                These terms and conditions outline the rules and regulations for the use of Vaal Triangle Couriers App.
            </Typography>
            <Typography variant="h6" gutterBottom>
                App Use
            </Typography>
            <Typography variant="body1" paragraph>
                You may not, except with our express written permission, distribute or commercially exploit the content of this app. Nor may you transmit it or store it in any other website or other form of electronic retrieval system.
            </Typography><Typography variant="h6" gutterBottom>
                Reverse Engineering and Unauthorized Use
            </Typography>
            <Typography variant="body1" paragraph>
                Reverse engineering, decompiling, or disassembling the code of the Vaal Triangle Couriers App is strictly prohibited. Users are not permitted to undertake any such actions for the purpose of extracting proprietary algorithms, source code, or any other intellectual property contained within the app. Additionally, the use of automated systems or software to extract data or information from this app for commercial purposes ('screen scraping') is prohibited unless explicitly authorized by Vaal Triangle Couriers. Any attempt to reverse engineer or unauthorized use of the code or content of the app will be considered a breach of these terms and may result in legal action being taken to protect the intellectual property and proprietary rights of Vaal Triangle Couriers.
            </Typography>
            <Typography variant="h6" gutterBottom>
                Privacy
            </Typography>
            <Typography variant="body1" paragraph>
                User's privacy is extremely important to us. Our privacy policy outlines our principles when it comes to the collection, processing, and storage of your information.
            </Typography>
            <Typography variant="h6" gutterBottom>
                Intellectual Property
            </Typography>
            <Typography variant="body1" paragraph>
                Unless otherwise stated, Vaal Triangle Couriers and/or its licensors own the intellectual property rights for all material on this app. All intellectual property rights are reserved.
            </Typography>
            <Typography variant="h6" gutterBottom>
                Limitation of Liability
            </Typography>
            <Typography variant="body1" paragraph>
                In no event shall Vaal Triangle Couriers, nor any of its officers, directors and employees, be held liable for anything arising out of or in any way connected with your use of this app.
            </Typography>
            <Typography variant="h6" gutterBottom>
                Changes to Terms
            </Typography>
            <Typography variant="body1" paragraph>
                Vaal Triangle Couriers reserves the right to revise these terms at any time. By using this app, you agree to be bound by any such changes.
            </Typography>
            <Typography variant="h6" gutterBottom>
                Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
                If you have any questions about these terms, please email johan@beneckegroup.net or call +27825687110
            </Typography>
        </Container>
    );
}

export default TermsAndConditions;
