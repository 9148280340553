import React from 'react';
import { Container, Typography, Box, Link, List, ListItem } from '@mui/material';
import Logo from '../../assets/images/logo512.png';

const GuardianPrivacyPolicy = () => {
    return (
        <Container maxWidth="md" style={styles.container}>
            <Box display="flex" justifyContent="center" marginBottom={3}>
                <img
                    src={Logo}
                    alt="Logo"
                    style={styles.logo}
                />
            </Box>
            <Typography variant="h4" component="h1" gutterBottom>
                Privacy Policy for TDT Dashboard Activity Monitor and Data Sync Chrome Extension
            </Typography>
            <Typography paragraph>
                Thank you for using our Chrome web extension. Your privacy is very important to us, and we want you to understand how we treat your information when you use our extension.
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
                1. No Personal Data Collection
            </Typography>
            <Typography paragraph>
                Our Chrome web extension does not collect, store, or share any personal information from users. We are committed to ensuring that your privacy is protected, and our extension operates entirely without collecting or processing personal user data or activity.
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
                2. Limited Use Scope
            </Typography>
            <Typography paragraph>
                This Chrome extension is available exclusively for use on two domains:
            </Typography>
            <List>
                <ListItem>
                    <Link href="https://admin.beneckegroup.net" target="_blank" rel="noopener noreferrer">
                        admin.beneckegroup.net
                    </Link>
                </ListItem>
                <ListItem>
                    <Link href="https://branch-dashboard.takealot.delivery" target="_blank" rel="noopener noreferrer">
                        branch-dashboard.takealot.delivery
                    </Link>
                </ListItem>
            </List>
            <Typography paragraph>
                The extension is designed specifically for use with these domains and does not operate or interact with any other websites or services.
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
                3. Permissions
            </Typography>
            <Typography paragraph>
                The permissions requested by the extension are limited to enabling the functionality strictly necessary for its intended use on the aforementioned domains. These permissions do not allow us to access or collect personal information.
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
                4. No Third-Party Sharing
            </Typography>
            <Typography paragraph>
                Since we do not collect any personal information, there is nothing to share with third parties. Your usage of this extension remains completely private.
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
                5. Changes to This Privacy Policy
            </Typography>
            <Typography paragraph>
                We may update this privacy policy from time to time to reflect changes in legal requirements or operational adjustments. Any changes will be updated here, and the date of the last update will be clearly indicated.
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
                6. Contact Information
            </Typography>
            <Typography paragraph>
                If you have any questions about this privacy policy, please contact us at:
            </Typography>
            <Typography paragraph>
                <strong>Email:</strong> <Link href="mailto:mrd@wol.co.za">mrd@wol.co.za</Link>
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
                7. Acceptance of Terms
            </Typography>
            <Typography paragraph>
                By using this Chrome extension, you signify your acceptance of this privacy policy. If you do not agree with this policy, please do not use our extension.
            </Typography>
            <Typography paragraph>
                <strong>[Date of Last Update: November 13, 2024]</strong>
            </Typography>
        </Container>
    );
};

const styles = {
    container: {
        padding: '20px',
        backgroundColor: '#f9f9f9',
        color: '#333',
    },
    logo: {
        width: '150px',
        height: 'auto',
    }
};

export default GuardianPrivacyPolicy;
